<template>
  <div class="chart-loading">
    <div 
      v-if="mode=='wave'"
      class="loading-container"
    >
      <img
        v-for="i in waves"
        :key="'loading-wave'+i"
        src="/img/loading-wave.svg"
        class="wave"
        :class="['position'+(position-i)]"
        :style="{ 'width': width, 'height': height }"
      >
    </div>
    <div 
      v-else
      class="loading-container neon"
    >
      <div
        class="limit"
      />
    </div>
  </div>
</template>

<style scoped>

  .chart-loading, .loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .chart-loading .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(0%);
  }
  .chart-loading .wave.position0 {
    transform: translateX(0%);
    animation: p0 2s linear;
  }
  .chart-loading .wave.position-1 {
    transform: translateX(-100%);
    animation: p-1 2s linear;
  }
  .chart-loading .wave.position1 {
    transform: translateX(-100%);
    animation: p-1 2s linear;
  }

  @keyframes p0 {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @keyframes p-1 {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .chart-loading .neon .limit {
    background: radial-gradient(circle at 30% -40%, white, rgba(255,255,255,0.95) 50%, rgba(255,255,255,.4) 70%);
    background-size: 200% 200%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: neon 8s linear infinite;
  }
  .chart-loading .neon {
    background: linear-gradient(-45deg, #F85973, #698DF2);
    background-size: 400% 400%;
    animation: neon 2s ease infinite;
  }

  @keyframes neon {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  /* @keyframes neon {
    0% {
      background: linear-gradient(-45deg, #F85973, #698DF2);
      transform: rotate(0deg);
      background-position: 0% 50%;
    }
    50% {
      background: linear-gradient(120deg, #F85973, #698DF2);
      transform: rotate(270deg);
      background-position: 100% 50%;
    }
    100% {
      background: linear-gradient(-45deg, #F85973, #698DF2);
      transform: rotate(0deg);
      background-position: 0% 50%;
    }
  } */

  svg {
    display: block;
    width: 100%;
  }
</style>

<script>
  export default {
    props: {
      width: {
        type: [Number, String],
        default: '200%'
      },
      height: {
        type: [Number, String],
        default: '25%'
      },
      mode: {
        type: String,
        default: 'wave'
      }
    },
    data: () => ({
      controller: null,
      waves: [0,1],
      position: 0,
    }),

    mounted () {
      this.controller = setInterval(($) => {
        $.position = $.position+1 < $.waves.length ? $.position + 1 : 0;
      }, 2000, this);
    },
    beforeDestroy () {
      clearInterval(this.controller);
    },
    components: {
      // IconBase: () => import('@/components/IconBase'),
      // Wave: () => import('@/components/icons/LoadingWave'),
    }
  }
</script>